import styled from '@emotion/styled';
import MainLayout from 'components/Dashboard/Layout/MainLayout';
import Trips from 'components/Dashboard/Trips/Trips';

const Container = styled('div')`
	background: #e5e5e5;
	min-height: 1300px;
`;

const BackgroundContainer = styled('div')`
	background: linear-gradient(
			to bottom,
			rgba(237, 240, 244, 0) 0%,
			rgba(237, 240, 244, 0) 80%,
			#e5e5e5 100%
		),
		url('/assets/sky_clouds.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 1080px;
`;

const PageContainer = styled('div')`
	padding-bottom: 5rem;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 4rem;
	}
`;

const Header = styled('div')`
	padding-top: 2rem;
	font-weight: 600;
	font-size: 24px;
	color: #ffffff;
	margin: 0 2rem;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0;
		font-size: 56px;
		line-height: 75px;
		padding: 3rem 0;
	}
`;

const TripsPage = () => (
	<MainLayout>
		<Container>
			<BackgroundContainer>
				<PageContainer>
					<Header>My trips</Header>
					<Trips />
				</PageContainer>
			</BackgroundContainer>
		</Container>
	</MainLayout>
);

export default TripsPage;
